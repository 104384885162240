import React from 'react';

export default function Hover() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<g>
				<path
					d="M341.356,170.869c-0,-94.193 -76.474,-170.666 -170.667,-170.666c-94.194,-0 -170.667,76.473 -170.667,170.666c0,94.194 76.473,170.667 170.667,170.667c94.193,0 170.667,-76.473 170.667,-170.667Zm-32.429,0c-0,76.296 -61.943,138.238 -138.238,138.238c-76.296,0 -138.238,-61.942 -138.238,-138.238c-0,-76.295 61.942,-138.238 138.238,-138.238c76.295,0 138.238,61.943 138.238,138.238Z"
			        style={{ fill: '#1c9462' }}
				/>
			</g>
		</svg>
	);
}
