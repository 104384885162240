import React from "react";

export default function Display() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M238.772,170.758C238.772,133.316 208.374,102.918 170.932,102.918C133.49,102.918 103.091,133.316 103.091,170.758C103.091,208.2 133.49,238.598 170.932,238.598C208.374,238.598 238.772,208.2 238.772,170.758ZM206.772,170.758C206.772,190.539 190.713,206.598 170.932,206.598C151.151,206.598 135.091,190.539 135.091,170.758C135.091,150.977 151.151,134.918 170.932,134.918C190.713,134.918 206.772,150.977 206.772,170.758Z"
				style={{ fill: '#d3d3d3' }}
			/>
		</svg>
	);
}
