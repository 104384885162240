import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './DateSelector.scss';
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DateSelector(props) {
	const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const [centerDate, setCenterDate] = useState(new Date());
	const year = centerDate.getFullYear();
	const month = centerDate.getMonth();
	const firstDay = new Date(year, month, 1);
	const lastDay = new Date(year, month + 1, 0);
	useEffect(() => {
		if (props.type !== 'range') return;
		if (props.value.begin > props.value.end){
			props.onChange({begin: props.value.end, end: props.value.begin});
		}
	}, [props.value])

	const renderDay = (date, thisMonth = true) => {
		switch(props.type) {
			case 'range':
				const isBegin = props.value?.begin?.getTime?.() === date.getTime();
				const isEnd = props.value?.end?.getTime?.() === date.getTime();
				const hasRange = props.value?.begin?.getTime?.() !== props.value?.end?.getTime?.();
				const selected = isBegin || isEnd;
				if (!props.value.begin){
					return (
						<span
							key={date.getTime()}
							className={`${selected ? 'selected' : ''} ${thisMonth ? '' : 'otherMonth'}`}
							onClick={() => props.onChange({ begin: date, end: date})}
						>
						{date.getDate()}
					</span>
					);
				}

				const inRange = props.value?.begin && props.value?.end && date.getTime() > props.value.begin.getTime() && date.getTime() < props.value.end.getTime()
				return (
					<span
						key={date.getTime()}
						className={`${selected ? `selected ${hasRange ? (isBegin ? 'begin' : 'end') : ''}` : ''} ${inRange ? 'inRange' : ''} ${thisMonth ? '' : 'otherMonth'}`}
						onClick={() => {
							if (isBegin){
								if (isEnd) return props.onChange({});
								return props.onChange({...props.value, begin: props.value.end});
							}
							if (isEnd){
								return props.onChange({...props.value, end: props.value.begin});
							}
							if (date.getTime() < props.value.begin.getTime()) {
								return props.onChange({...props.value, begin: date})
							}

							props.onChange({...props.value, end: date})
						}}
					>
						{date.getDate()}
					</span>
				);

			case 'select':
			default:
				return (
					<span
						key={date.getTime()}
						className={`${date.getTime() === props.value?.getTime?.() ? 'selected' : ''} ${thisMonth ? '' : 'otherMonth'}`}
						onClick={() => {
							if (date.getTime() === props.value?.getTime?.()){
								return props.onChange();
							}
							props.onChange(date)
						}}
					>
						{date.getDate()}
					</span>
				);
		}

	};
	const renderDays = () => {
		const firstDateDay = (firstDay.getDay() + 6) % 7;
		const lastDateDay = (lastDay.getDay() + 6) % 7;

		let lastMonth = [];
		for (let i = 0; i < firstDateDay; i++){
			lastMonth.push(new Date(year, month, i - (firstDateDay - 1)));
		}

		let thisMonth = [];
		for (let i = 1; i <= lastDay.getDate(); i++){
			thisMonth.push(new Date(year, month, i));
		}

		let nextMonth = [];
		for (let i = 0; i < 6 - lastDateDay; i++){
			nextMonth.push(new Date(year, month + 1, i + 1));
		}
		return (
			<>
				{lastMonth.map(d =>  (renderDay(d, false)))}
				{thisMonth.map(d =>  (renderDay(d)))}
				{nextMonth.map(d =>  (renderDay(d, false)))}
			</>
		);
	};

	return (
		<div className={'DateSelector'}>
			<div className={'month'}>
				<span className={'monthNavigator'} onClick={() => setCenterDate(new Date(year, month - 1, 1))} ><FontAwesomeIcon icon={faCaretLeft}/></span>
				<span>{`${monthNames[month]} ${year} `}</span>
				<span className={'monthNavigator'} onClick={() => setCenterDate(new Date(year, month + 1, 1))} ><FontAwesomeIcon icon={faCaretRight}/></span>
			</div>

			<div className={'days'}>
				<div className={'dayNames'}>
					<span>mo</span><span>tu</span><span>wo</span><span>th</span><span>fr</span><span>sa</span><span>su</span>
				</div>
				<div className={'cells'}>
					{renderDays()}
				</div>
			</div>
		</div>
	);
}
DateSelector.propTypes = {
	onChange: PropTypes.func.isRequired,

	// disabled: PropTypes.bool,
	//
	// notBefore: PropTypes.instanceOf(Date),
	// notAfter: PropTypes.instanceOf(Date),

	value: PropTypes.oneOfType([
		PropTypes.instanceOf(Date),
		PropTypes.shape({
			begin: PropTypes.instanceOf(Date),
			end: PropTypes.instanceOf(Date)
		})
	]),
	type: PropTypes.oneOf(['select', 'range']),
	// duration: PropTypes.instanceOf(Date),
};

export default DateSelector;
