import React from 'react';

export default function Hover() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<g>
				<path
					d="M341.595,83.272c-0,-45.847 -37.223,-83.069 -83.07,-83.069l-175.194,-0c-45.847,-0 -83.07,37.222 -83.07,83.069l0,175.194c0,45.848 37.223,83.07 83.07,83.07l175.194,0c45.847,0 83.07,-37.222 83.07,-83.07l-0,-175.194Zm-32.429,0l-0,175.194c-0,27.95 -22.691,50.641 -50.641,50.641l-175.194,0c-27.95,0 -50.641,-22.691 -50.641,-50.641c0,0 0,-175.194 0,-175.194c0,-27.949 22.691,-50.641 50.641,-50.641l175.194,0c27.95,0 50.641,22.692 50.641,50.641Z"
					style={{
						fill: '#1c9462'
					}}
				/>
				<path
					d="M143.624,190.112l98.143,-116.692l41.325,34.756l-133.126,158.287l-91.607,-77.046l34.983,-41.595l50.282,42.29Z"
					style={{
						fill: '#1c9462'
					}}
				/>
			</g>
		</svg>
	);
}
