import React from 'react';

export default function Normal() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<g>
				<path
					d="M238.572,170.667c-0,-37.442 -30.399,-67.841 -67.841,-67.841c-37.442,0 -67.84,30.399 -67.84,67.841c0,37.442 30.398,67.84 67.84,67.84c37.442,-0 67.841,-30.398 67.841,-67.84Z"
					style={{ fill:'#1c9462' }}
				/>
				<path
					d="M341.398,170.667c0,-94.194 -76.473,-170.667 -170.667,-170.667c-94.193,0 -170.666,76.473 -170.666,170.667c-0,94.193 76.473,170.666 170.666,170.666c94.194,0 170.667,-76.473 170.667,-170.666Zm-32.429,-0c0,76.295 -61.942,138.238 -138.238,138.238c-76.295,-0 -138.238,-61.943 -138.238,-138.238c0,-76.296 61.943,-138.238 138.238,-138.238c76.296,-0 138.238,61.942 138.238,138.238Z"
					style={{ fill:'#1c9462' }}
				/>
			</g>
		</svg>
	);
}
