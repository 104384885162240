import React from 'react';

export default function Disabled() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M143.624,190.001l98.143,-116.692l41.325,34.756l-133.126,158.287l-91.607,-77.046l34.983,-41.595l50.282,42.29Z"
			    style={{ fill: '#1c9462' }}
			/>
		</svg>
	);
}
