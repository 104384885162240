import React from 'react';

export default function Normal() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<g>
				<path
					d="M341.5,83.07c-0,-45.848 -37.222,-83.07 -83.07,-83.07l-175.194,0c-45.847,0 -83.07,37.222 -83.07,83.07l0,175.194c0,45.847 37.223,83.069 83.07,83.069l175.194,0c45.848,0 83.07,-37.222 83.07,-83.069l-0,-175.194Z"
					style={{ fill: '#c1222f' }}
				/>
				<path
					d="M267.354,116.245l-42.099,-42.1l-150.943,150.944l42.099,42.099l150.943,-150.943Z"
					style={{ fill: '#fff' }}
				/>
				<path
					d="M225.255,267.188l42.099,-42.099l-150.943,-150.944l-42.099,42.1l150.943,150.943Z"
					style={{ fill: '#fff' }}
				/>
			</g>
		</svg>
	);
}
