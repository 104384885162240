import React from 'react';

export default function Focus() {
	return (
		<svg
			viewBox="0 0 364 364"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M363.173,88.507c0,-48.765 -39.591,-88.357 -88.357,-88.357l-186.345,0c-48.765,0 -88.357,39.592 -88.357,88.357l0,186.345c0,48.766 39.592,88.357 88.357,88.357l186.345,0c48.766,0 88.357,-39.591 88.357,-88.357l0,-186.345Zm-12,0l0,186.345c0,42.143 -34.214,76.357 -76.357,76.357l-186.345,0c-42.142,0 -76.357,-34.214 -76.357,-76.357c0,0 0,-186.345 0,-186.345c0,-42.142 34.215,-76.357 76.357,-76.357l186.345,0c42.143,0 76.357,34.215 76.357,76.357Z"
			/>
		</svg>
	);
}
