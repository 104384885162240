import React from 'react';

export default function Hover() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<g>
				<path
					d="M341.398,170.869c0,-94.193 -76.473,-170.666 -170.667,-170.666c-94.193,-0 -170.666,76.473 -170.666,170.666c-0,94.194 76.473,170.667 170.666,170.667c94.194,0 170.667,-76.473 170.667,-170.667Zm-102.826,0c-0,-37.442 -30.399,-67.84 -67.841,-67.84c-37.442,0 -67.84,30.398 -67.84,67.84c0,37.442 30.398,67.841 67.84,67.841c37.442,-0 67.841,-30.399 67.841,-67.841Z"
					style={{ fill: '#1c9462' }}
				/>
			</g>
		</svg>
	);
}
