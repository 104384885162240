import React, { useEffect } from 'react';
import './PermissionsInput.scss';

const CLICK_ON_HIGHEST_ACTIVE_LEVEL_SHOULD_DO_NOTHING = false;

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export default function PermissionsInput(props) {
	const {
		t,
		usePermissions
	} = props;

	const { permissions, groupedPermissions, error } = usePermissions();

	useEffect(() => {
		if (!props.value) props.onChange([]);
	}, []);
	
	const onPermissionClick = (permission) => {
		if (props.disabled) return;

		const typePermissions = permissions
			// Only permissions of this type
			.filter(p => p.type === permission.type)
			// Sort by level
			.sort((a, b) => a.level - b.level);

		if (permission.level === 0) {
			const codesOfThisType = typePermissions
				.map(p => p.code);
			return props.onChange(props.value.filter(code => !codesOfThisType.includes(code)));
		}

		// Do we have this level?
		if (props.value?.includes(permission.code)) {

			// Do we have the level above?
			const codeOfLevelAbove = typePermissions[permission.level]?.code;
			if (props.value?.includes(codeOfLevelAbove)) {

				// Remove all levels above
				const codesOfAllLevelsAbove = typePermissions
					.slice(permission.level, typePermissions.length)
					.map(p => p.code);
				const newValue = props.value?.filter(code => !codesOfAllLevelsAbove.includes(code)) ?? [];
				return props.onChange(newValue);
			}

			// Remove only this level
			if (CLICK_ON_HIGHEST_ACTIVE_LEVEL_SHOULD_DO_NOTHING) return;
			return props.onChange(props.value?.filter(code => code !== permission.code) ?? []);
		}

		// Add this level and everything below
		const codes = typePermissions
			// Remove permissions higher than the clicked level
			.slice(0, permission.level)
			// We only want the code
			.map(p => p.code);

		// Add to the existing value
		const newValue = [...props.value ?? [], ...codes].filter(onlyUnique);
		return props.onChange(newValue);
	};

	const isActive = (permission) => {
		if (permission.level !== 0) return props.value?.includes(permission.code) ?? false;
		
		const typeCodes = permissions
			.filter(p => p.type === permission.type)
			.map(p => p.code);

		const hasAny = typeCodes.some(code => props.value?.includes(code));

		return !hasAny;
	};

	const renderTypes = (types) => types?.map(type => (
		<div key={type.type} className='permissions__type'>
			<h4>{t(`permissions.type_${type.type}`)}</h4>

			<div className='permissions'>
				<div className='level__group'>
					<div
						className={`permission permission--0 ${isActive({ level: 0, type: type.type }) ? 'active' : ''}`}
						onClick={(e) => onPermissionClick({
							level: 0,
							type: type.type,
						})}
					>
						<span className='label'>{t(`permissions.level_0`)}</span>
						<span className='circle'></span>
					</div>
				</div>

				<div className='level__group'>
					{type?.permissions.map(permission => (
						<div
							key={`${permission.type}-${permission.level}`}
							className={`permission permission--${permission.level} ${isActive(permission) ? 'active' : ''}`}
							onClick={(e) => onPermissionClick(permission)}
						>
							<span className='label'>
								{t(`permissions.${permission.type}_${permission.level}`)}
							</span>
							<span className='circle'></span>
						</div>
					))}
				</div>
			</div>
		</div>
	));

	const renderGroups = (groups) => (
		<div className='permissions__groups'>
			{groups?.map(group => (
				<div key={group.group} className='permissions__group'>
					{groups.length > 1 && <h3>{group.group}</h3>}
					{renderTypes(group.types)}
				</div>
			))}
		</div>
	);

	return (
		<div className={`PermissionsInput ${props.disabled ? 'disabled' : ''}`}>
			{renderGroups(groupedPermissions)}
		</div>
	);
}
