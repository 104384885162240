import React from 'react';
import Normal from './icons/normal';
import Radio from './icons/radio';
import Inverted from './icons/inverted';

export default function CheckboxIcon(props) {
	const {
		inverted,
		radio,
		disabled,
		display,
		checked,
		hoverOver
	} = props;

	const getSet = (inverted, radio) => {
		if (radio) return Radio;
		if (inverted) return Inverted;
		return Normal;
	};

	const getIcon = (inverted, radio, checked) => {
		const Set = getSet(inverted, radio);

		if (checked) return Set.checked;
		return Set.unchecked;
	}

	const Icon = getIcon(inverted, radio, checked);

	const renderIcon = () => {
		if (display) return <Icon.Display />;
		if (disabled) return <Icon.Disabled />;
		if (hoverOver) return <Icon.Hover />;
		return <Icon.Normal />
	}

	const renderFocusRing = () => {
		return (
			<span className={'focus'}>
				<Icon.Focus />
			</span>
		)
	}

	return (
		<span className={'CheckboxIcon'}>
			<span className={'icon'}>
				{renderIcon()}
			</span>
			{renderFocusRing()}
		</span>
	);
}
