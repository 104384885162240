import React from 'react';

export default function Normal() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<g>
				<path
					d="M341.595,83.07c-0,-45.848 -37.223,-83.07 -83.07,-83.07l-175.194,0c-45.847,0 -83.07,37.222 -83.07,83.07l0,175.194c0,45.847 37.223,83.069 83.07,83.069l175.194,0c45.847,0 83.07,-37.222 83.07,-83.069l-0,-175.194Z"
					style={{
						fill: '#1c9462'
					}}
				/>
				<path
					d="M143.624,189.909l98.143,-116.691l41.325,34.755l-133.126,158.287l-91.607,-77.045l34.983,-41.595l50.282,42.289Z"
					style={{
						fill: '#fff'
					}}
				/>
			</g>
		</svg>
	);
}
