import React from 'react';
import './Spinner.scss';

function Spinner(props) {
	const renderSmall = () => {
		return (
			<div className={'Spinner Spinner--small'}>
				<div className='circle'></div>
			</div>
		);
	};

	const renderDefault = () => {
		return (
			<div className={'Spinner'}>
				<div className='circle'></div>
				<span className={'text'}>{'Laden'}&hellip;</span>
			</div>
		);
	};

	switch (props.size) {
		case 'small':
			return renderSmall();
		default:
			return renderDefault();
	}
}

export default Spinner;
