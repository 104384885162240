import React from 'react';

export default function Display() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M238.772,170.758c-0,-37.442 -30.398,-67.84 -67.84,-67.84c-37.442,-0 -67.841,30.398 -67.841,67.84c0,37.442 30.399,67.84 67.841,67.84c37.442,0 67.84,-30.398 67.84,-67.84Z"
				style={{ fill: '#1c9462' }}
			/>
		</svg>
	);
}
