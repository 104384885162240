import React from 'react';
import DatePicker from '../DatePicker';

export default function DateRangeFilter({ value, setValue, translations }) {
	return (
		<div className='ListFilter DateRangeFilter'>
			<DatePicker
				translations={translations}
				value={value}
				onChange={setValue}
			/>
		</div>
	);
}

export const dateRangeTest = (date, [start, end]) => {
	if (!start && !end) return true;
	if (!end) return start?.getTime() <= date.getTime();
	if (!start) return end?.getTime() >= date.getTime();
	return start?.getTime() <= date.getTime() && end?.getTime() >= date.getTime();
};

export const isDateFilterActive = (state) => state && !state.every(d => d === null || d === undefined);
