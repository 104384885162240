import Normal from "./Normal";
import Hover from "./Hover";
import Disabled from "./Disabled";
import Display from "./Display";
import Focus from "./Focus";

export default {
	Normal,
	Hover,
	Disabled,
	Display,
	Focus
}
