import React from 'react';

export default function Focus() {
	return (
		<svg
			viewBox="0 0 364 364"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path d="M363.265,181.68c-0,-100.189 -81.341,-181.53 -181.53,-181.53c-100.188,0 -181.529,81.341 -181.529,181.53c-0,100.189 81.341,181.529 181.529,181.529c100.189,0 181.53,-81.34 181.53,-181.529Zm-12,-0c-0,93.566 -75.964,169.529 -169.53,169.529c-93.565,0 -169.529,-75.963 -169.529,-169.529c-0,-93.566 75.964,-169.53 169.529,-169.53c93.566,0 169.53,75.964 169.53,169.53Z"/>
		</svg>
	);
}
