import React from 'react';

export default function Disabled() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M170.833,128.688l54.422,-54.422l42.099,42.1l-54.422,54.422l54.422,54.422l-42.099,42.099l-54.422,-54.422l-54.422,54.422l-42.099,-42.099l54.422,-54.422l-54.422,-54.422l42.099,-42.1l54.422,54.422Z"
			    style={{
			        fill: '#d3d3d3'
			    }}
			/>
			<path
				d="M341.5,83.191c-0,-45.848 -37.222,-83.07 -83.07,-83.07l-175.194,0c-45.847,0 -83.07,37.222 -83.07,83.07l0,175.194c0,45.847 37.223,83.069 83.07,83.069l175.194,0c45.848,0 83.07,-37.222 83.07,-83.069l-0,-175.194Zm-32.429,-0l0,175.194c0,27.949 -22.691,50.641 -50.641,50.641c0,-0 -175.194,-0 -175.194,-0c-27.949,-0 -50.641,-22.692 -50.641,-50.641c0,-0 0,-175.194 0,-175.194c0,-27.95 22.692,-50.641 50.641,-50.641l175.194,-0c27.95,-0 50.641,22.691 50.641,50.641Z"
				style={{
					fill: '#d3d3d3'
				}}
			/>
		</svg>
	);
}
