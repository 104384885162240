import React from 'react';

export default function Display() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M267.354,116.336l-42.099,-42.099l-150.943,150.943l42.099,42.099l150.943,-150.943Z"
				style={{
					fill: '#c1222f'
				}}
			/>
			<path
				d="M225.255,267.279l42.099,-42.099l-150.943,-150.943l-42.099,42.099l150.943,150.943Z"
				style={{
					fill: '#c1222f'
				}}
			/>
		</svg>
	);
}
