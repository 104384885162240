import React from 'react';

export default function Disabled() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M341.356,170.788c-0,-94.194 -76.474,-170.667 -170.667,-170.667c-94.194,0 -170.667,76.473 -170.667,170.667c0,94.193 76.473,170.666 170.667,170.666c94.193,0 170.667,-76.473 170.667,-170.666Zm-32.429,-0c-0,76.295 -61.943,138.238 -138.238,138.238c-76.296,-0 -138.238,-61.943 -138.238,-138.238c-0,-76.296 61.942,-138.238 138.238,-138.238c76.295,-0 138.238,61.942 138.238,138.238Z"
				style={{ fill: '#d3d3d3' }}
			/>
		</svg>
	);
}
