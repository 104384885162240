import React from 'react';

export default function Normal() {
	return (
		<svg
			viewBox="0 0 342 342"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: 'evenodd',
				clipRule: 'evenodd',
				strokeLinejoin: 'round',
				strokeMiterlimit: 2
			}}
		>
			<path
				d="M341.536,83.07c0,-45.848 -37.222,-83.07 -83.069,-83.07l-175.195,0c-45.847,0 -83.069,37.222 -83.069,83.07l-0,175.194c-0,45.847 37.222,83.069 83.069,83.069l175.195,0c45.847,0 83.069,-37.222 83.069,-83.069l0,-175.194Zm-32.428,-0l-0,175.194c-0,27.949 -22.692,50.641 -50.641,50.641l-175.195,-0c-27.949,-0 -50.64,-22.692 -50.64,-50.641l-0,-175.194c-0,-27.95 22.691,-50.641 50.64,-50.641l175.195,-0c27.949,-0 50.641,22.691 50.641,50.641Z"
				style={{ fill: '#d3d3d3' }}
			/>
		</svg>
	);
	// return (<span>normal unchecked</span>)
}
